import React, {useState} from 'react';
import AuthLayout from '@mgp-fe/shared/modules/auth/components/AuthLayout.tsx';
import LoginForm from '@mgp-fe/shared/modules/auth/components/LoginForm.tsx';
import Logo from '@mgp-fe/shared/assets/logo/text-logo.svg';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import ForgotPasswordForm from '@mgp-fe/shared/modules/auth/components/ForgotPasswordForm.tsx';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RegisterProxyContent from '@mgp-fe/shared/modules/auth/components/RegisterProxyContent.tsx';

export default function AuthPage({app, registerButton}: AuthPageProps) {
	const [mode, setMode] = useState<AuthIndexPageMode>('login');
	const registerModalOpened = useToggle({initialState: false});

	return <AuthLayout>
		<main
			className='md:h-fit my-auto rounded-xl flex flex-col items-center md:flex-row md:flex-wrap justify-center w-full md:w-[720x] lg:w-[940px] shadow-inner shadow-primary/10'>
			<section
				className='w-full md:w-1/2 self-stretch bg-background rounded-l-3xl srhink-0 flex flex-col gap-4 md:gap-8 items-center justify-center py-6 md:py-0'>
				<Logo className='fill-primary w-52 md:w-72 lg:w-80'/>
				<h1 className='h3 md:h2 lg:h3 uppercase text-secondary text-4xl font-semibold'>{app} area</h1>
			</section>

			<section
				className='w-full shrink-0 md:w-1/2 bg-background-light/5 backdrop-blur-md rounded-r-3xl px-10 py-6 flex flex-col justify-center items-stretch'>
				{{
					'login': <div className='flex flex-col gap-4 items-start w-full'>
						<article>
							<h4 className='text-primary'>Login</h4>
							<p>If you already have an account.</p>
						</article>
						<LoginForm/>

						<Button
							onClick={() => setMode('forgotPassword')}
							variant='ghost'
							size='sm'
							className='w-fit'
							icon={<FontAwesomeIcon icon='question-circle'/>}>
							Forgot password
						</Button>
					</div>,

					'forgotPassword': <div className='flex flex-col gap-4 items-start w-full'>
						<ForgotPasswordForm/>
						<Button variant='ghost' size='sm' className='w-fit' onClick={() => setMode('login')}>
							<FontAwesomeIcon icon='arrow-left' className='mr-2'/>
							Login here!
						</Button>
					</div>,
				}[mode]}
			</section>

			<div className='w-full text-center px-3 mt-6 md:mt-8 lg:mt-12'>
				{registerButton ? registerButton : <Button
					variant='outline'
					size='md'
					onClick={() => registerModalOpened.on()}>
					{{
						'customer': 'Don\'t have an account yet? Register now!',
						'partner': 'Do you want to become a partner? Click here!',
						'affiliate': 'Do you want to become a affiliate? Click here!',
					}[app]}
				</Button>}
			</div>
		</main>

		<Modal isOpen={registerModalOpened.state} onClose={registerModalOpened.off}>
			<RegisterProxyContent />
		</Modal>
	</AuthLayout>;
}

interface AuthPageProps {
	app: 'customer' | 'partner' | 'affiliate';
	registerButton: React.ReactNode;
}

type AuthIndexPageMode = 'login' | 'forgotPassword';