import {createContext} from 'react';
import {OnboardingContextState} from './domain.ts';

export default createContext<OnboardingContextState>({
	selectedDesign: undefined,
	setSelectedDesign: () => undefined,
	onboardingStep: 'design',
	setOnboardingStep: () => undefined,
	mode: {},
	setMode: () => undefined,
});