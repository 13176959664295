import React, {useEffect, useState} from 'react';
import {ProductCollectionModel} from '@mgp-fe/shared/core-api/domain/product.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import MoneyFormatted from '@mgp-fe/shared/modules/formatters/MoneyFormatted.tsx';
import ProductCartControls from '@mgp-fe/shared/modules/shop/components/ProductCartControls.tsx';
import {Card} from '@mgp-fe/shared/ui/card.tsx';
import {cn, resolveEnvVar} from '@mgp-fe/shared/index.ts';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import Skeleton from '@mgp-fe/shared/ui/skeleton.tsx';

export default function ProductHeroCard(props: ProductHeroCardProps) {
	const {user} = useAuth();
	const [isWebEnv, setIsWebEnv] = useState(false);

	useEffect(() => {
		const webAppUrl = resolveEnvVar('webAppUrl');
		const currentUrl = window.location.href;
		setIsWebEnv(currentUrl.replace('www.', '').includes(webAppUrl));
	}, []);

	const detailsUrl = isWebEnv ? `pricing/${props.product.id}` : `product-details/${props.product.id}`;
	const designerUrl = resolveEnvVar('customerAppUrl') + (user ? '/gard-designs' : '/onboarding');

	return <Card
		className='relative flex flex-col md:flex-row items-stretch justify-evenly mb-8 '>
		<div
			className='relative overflow-hidden w-full flex flex-col items-center h-72 md:h-auto '
			style={{
				boxShadow: '-5px -105px 100% rgba(255, 255, 255, 0.02) inset',
			}}>
			<div
				className='z-10 absolute w-full h-4/5 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 aspect-square blur-lg -rotate-45'
				style={{
					background: 'radial-gradient(ellipse farthest-corner at 50%, rgb(50,50,50), rgb(50,50,50,0.4), rgba(50,50,50,0.05), black)',
				}}/>

			<div className='h-full w-full flex items-center'>
				<img
					className='w:full md:w-2/5 mx-auto z-20 '
					src={props.product.titleImage || ''}
					alt='Full custom gard'/>
			</div>

			<div className='flex flex-wrap absolute z-50 top-2 right-2 w-fit gap-mini'>
				{props.product.promotionActions.map(pa => <div
					key={pa['@id']}
					className='w-fit bg-secondary text-muted/90 py-mini px-small rounded-full'>
					-{pa.percentageDiscount}% Off
				</div>)}
			</div>

			<div
				className='z-40 md:absolute left-0 right-0 bottom-10 mx-auto px-10 max-w-[410px] xl:max-w-fit flex flex-wrap gap-4'>
				{['full_custom_mouthguard', 'simple_mouthguard'].includes(props.product.productType)
					? <Button
						size='lg'
						variant='outline'
						className='z-40 uppercase font-semibold w-full xl:w-fit flex items-center gap-4 text-base'
						asChild>
						<a href={designerUrl} target={user ? undefined : '_blank'} rel='noreferrer'>
							Design your own!
						</a>
					</Button>
					: ''}

				<div className='z-40 grow lg:grow-0 w-full xl:w-fit flex items-stretch'>
					<ProductCartControls product={props.product}/>
				</div>
			</div>
		</div>

		<article className='w-full md:w-1/2 shrink-0 p-8'>
			<h3 className='text-primary font-medium uppercase text-4xl mb-8 flex flex-col flex-wrap md:flex-row justify-between items-start md:items-center leading-relaxed'>
				{props.product.name || 'Untitled product'}

				<i className='grid shrink-0 text-2xl font-medium text-muted/90'>
					{props.product.discountedPrice.amount !== props.product.price.amount ?
						<span className={cn('line-through ml-auto text-sm text-muted/50')}>
							<MoneyFormatted money={props.product.price}/>
							<br/>
						</span> : ''}
					<MoneyFormatted money={props.product.discountedPrice}/>
				</i>
			</h3>
			<p className='text-lg font-normal'>
				Get a fully customized fit and design. Customize colors, jersey number, name and team motto. Upload your team logo & more!
			</p>

			<h4 className='text-xl font-medium mt-8 text-foreground'>Benefits</h4>
			<ul className='list-disc ml-6'>
				<li>Express yourself with a custom design</li>
				<li>Custom fit made from a scan of your teeth</li>
				<li>Speak and breath more comfortably</li>
				<li>Made of the highest-quality materials. BPA Free.</li>
			</ul>

			<div className='mt-small'>
				<a className='text-secondary' href={detailsUrl}>show details</a></div>
		</article>
	</Card>;
}

interface ProductHeroCardProps {
	product: ProductCollectionModel;
}

export function ProductHeroCardSkeleton() {
	return (
		<Card className='relative flex flex-col md:flex-row items-stretch justify-evenly mb-8'>

			<div className='relative overflow-hidden w-full flex flex-col items-center h-72 md:h-auto'>
				<Skeleton className='h-full w-full' />
				<div className='z-40 md:absolute left-0 right-0 bottom-10 mx-auto px-10 max-w-[410px] xl:max-w-fit flex flex-wrap gap-4'>
					<Button size='lg' variant='outline' className='w-full xl:w-fit' disabled>
						<Skeleton className='w-36 h-6' />
					</Button>

					<div className='grow lg:grow-0 w-full xl:w-fit'>
						<Skeleton className='h-10 w-40' />
					</div>
				</div>
			</div>

			<article className='w-full md:w-1/2 shrink-0 p-8'>
				<h3 className='mb-8'>
					<Skeleton className='w-2/3 h-8 mb-4' />
					<Skeleton className='w-1/4 h-6' />
				</h3>
				<Skeleton className='w-full h-4 mb-2' />
				<Skeleton className='w-3/4 h-4 mb-2' />
				<Skeleton className='w-1/2 h-4 mb-6' />

				<h4 className='mb-4'>
					<Skeleton className='w-1/3 h-6' />
				</h4>
				<ul>
					<Skeleton className='w-full h-4 mb-2' />
					<Skeleton className='w-4/5 h-4 mb-2' />
					<Skeleton className='w-3/4 h-4 mb-2' />
					<Skeleton className='w-2/3 h-4' />
				</ul>
			</article>

		</Card>
	);
}