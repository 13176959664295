import React, {useContext, useMemo} from 'react';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import useUpdateMeMutation from '@mgp-fe/shared/core-api/mutations/user/me.ts';
import useChangePasswordMutation from '@mgp-fe/shared/core-api/mutations/user/change-password.ts';
import {useAddressBookMutation} from '@mgp-fe/shared/core-api/mutations/address/address-book.ts';
import {FormProvider, useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {TextInput} from '@mgp-fe/shared/ui/form/TextInput.tsx';
import PhoneNumberInput from '@mgp-fe/shared/ui/form/PhoneNumberInput.tsx';
import DateInput from '@mgp-fe/shared/ui/form/DateInput.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {z} from 'zod';
import {phoneNumberSchema} from '@mgp-fe/shared/core-api/schemas/phone-number.ts';
import {passwordSchema} from '@mgp-fe/shared/core-api/schemas/user.ts';
import DefaultAddressesSection from './DefaultAddressesSection.tsx';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {OnboardingContext} from '@mgp-fe/shared/modules/onboarding';
import {AddressModel, AddressModelSchema} from '@mgp-fe/shared/core-api/domain/address.ts';
import {useMutation} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import {humanizeEnumValue} from '@mgp-fe/shared/utils/humanize-string.ts';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify.ts';
import {AddressValidationResponse} from '@mgp-fe/shared/modules/account/components/address-book/AddressForm.tsx';

export default function AccountIndexPage() {
	const onboardingContext = useContext(OnboardingContext);
	const {user} = useAuth<Customer>();
	const {notifyWarning} = useNotify();
	const updateMe = useUpdateMeMutation();
	const changePassword = useChangePasswordMutation();
	const addressBookMutation = useAddressBookMutation();
	const validationMutation = useMutation({
		mutationKey: keysResolver(endpoints.address.validate, 'post'),
		mutationFn: (address: AddressModel) => coreApiClient.post<AddressValidationResponse>(endpoints.address.validate, address),
	});

	const form = useForm<AccountFormValues>({
		resolver: zodResolver(UserIsReadyForOrderSchema),
		// @ts-ignore
		defaultValues: async () => {
			const userData = await coreApiClient.get<Customer>(endpoints.me.index);

			return {
				...userData.data,
				...(typeof userData.data.dateOfBirth === 'object' ? {dateOfBirth: userData.data.dateOfBirth?.toISOString().split('T')[0]} : {}),
				defaultShippingAddress: user?.defaultShippingAddress || undefined,
				defaultBillingAddress: user?.defaultBillingAddress || undefined,
				billingIsSameAsShipping: true,
				phone: userData.data.phone ? parsePhoneNumberFromString(userData.data.phone)?.number : undefined,
			} as z.infer<typeof UserIsReadyForOrderSchema>;
		},
	});

	const submitHandler = async (data: AccountFormValues) => {
		if (!data.addressValidationExecuted && !!data.defaultShippingAddress) {
			//@ts-ignore
			form.setValue('addressValidationExecuted', true);
			try {
				const validationResponse = await validationMutation.mutateAsync(data.defaultShippingAddress!);
				if ((validationResponse.data.result.address.missingComponentTypes || []).length > 0 || (validationResponse.data.result.address.unconfirmedComponentTypes || []).length > 0) {
					return;
				}
			} catch (e) {
				notifyWarning('Address validation was not successful.');
			}
		}

		await updateMe.mutateAsync({
			name: data.name,
			email: data.email,
			phone: data.phone,
		});
		await Promise.all([
			data.password ? changePassword.mutateAsync({newPassword: data.password}) : Promise.resolve(),
			data.defaultShippingAddress && !user?.defaultShippingAddress ? await addressBookMutation.mutateAsync({
				data: {
					address: data.defaultShippingAddress,
					setAsDefaultShipping: true,
					setAsDefaultBilling: data.billingIsSameAsShipping,
				},
			}) : Promise.resolve(),
			!data.billingIsSameAsShipping && data.defaultBillingAddress && !user?.defaultBillingAddress ? await addressBookMutation.mutateAsync({
				data: {
					address: data.defaultBillingAddress,
					setAsDefaultShipping: false,
					setAsDefaultBilling: true,
				},
			}) : Promise.resolve(),
		]);

		onboardingContext.setOnboardingStep('order');
	};

	const isAddressValid = useMemo(
		() => (validationMutation.data?.data?.result.address.missingComponentTypes || []).length === 0 && (validationMutation.data?.data?.result.address.unconfirmedComponentTypes || []).length === 0,
		[validationMutation.data],
	);

	// This should never have appeared, just for type safety
	if (!user) return <>Loading</>;

	const isReadyToOrder = 1 || UserIsReadyForOrderSchema.safeParse(user).success;

	return <FormProvider {...form}>
		<form onSubmit={form.handleSubmit(submitHandler)}>
			<div className='grid md:grid-cols-2 gap-large mb-medium'>
				<section>
					<h3 className='text-muted'>Personal information</h3>

					<TextInput label='Name' name='name'/>
					<TextInput label='E-mail address' name='email' type='email'/>
					<PhoneNumberInput label='Phone number' name='phone'/>
					<DateInput label='Date of birth' name='dateOfBirth'/>
					{!user.passwordSet ? <TextInput label='Password' type='password' name='password'/> : ''}
				</section>
				<section>
					<DefaultAddressesSection/>
				</section>
			</div>


			<input type='hidden' {...form.register('addressValidationExecuted', {value: false})} />

			<div className='flex flex-col-reverse md:flex-row flex-wrap justify-between items-center mt-small'>
				<Button type='button' variant='link' className='text-muted/80 md:pl-0 cursor-pointer' asChild>
					<Button onClick={() => onboardingContext.setOnboardingStep('design')} variant='link' size='sm'>
						<FontAwesomeIcon icon='chevron-left' className='mr-2'/>
						Select design
					</Button>
				</Button>

				{isAddressValid ? <Button
					className='w-full md:w-fit mx-auto md:mr-0'
					state={validationMutation.isLoading || updateMe.isLoading || addressBookMutation.isLoading || changePassword.isLoading ? 'loading' : 'idle'}
					disabled={!isReadyToOrder}
					icon={<FontAwesomeIcon icon='chevron-right' className='mr-2'/>}
					iconPosition='right'>
					Checkout
				</Button> : <Alert variant='warning' className='w-full lg:w-1/2'>
					<AlertTitle>Please confirm your address</AlertTitle>
					<AlertDescription className='flex flex-col gap-small'>
						<span><strong>{[
							...(validationMutation.data?.data.result.address.missingComponentTypes || []),
							...(validationMutation.data?.data.result.address.unconfirmedComponentTypes || []),
						].map(v => humanizeEnumValue(v)).join(', ')}</strong> could be incorrect. Please check it.
						</span>
						<Button
							state={updateMe.isLoading || addressBookMutation.isLoading || changePassword.isLoading ? 'loading' : 'idle'}
							size='md'
							variant='outline'>
							Confirm & Save
						</Button>
					</AlertDescription>
				</Alert>}

			</div>
			{!isReadyToOrder ? <p className='text-sm text-warning mt-mini md:text-right'>
				Please enter personal information and addresses to continue.
			</p> : ''}
		</form>
	</FormProvider>;
}


const UserIsReadyForOrderSchema = z
	.object({
		defaultShippingAddress: AddressModelSchema,
		defaultBillingAddress: z.any(),
		addressValidationExecuted: z.boolean().or(z.null()).or(z.undefined()),
		email: z.string().email(),
		name: z.string().refine(name => name.trim().split(/\s+/).length >= 2, {message: 'Enter your full name (first and last name)'}),
		phone: phoneNumberSchema,
		billingIsSameAsShipping: z.boolean(),
		password: passwordSchema.or(z.null()).or(z.undefined()),
	})
	.refine(
		data => data.billingIsSameAsShipping || AddressModelSchema.safeParse(data.defaultBillingAddress).success,
		{
			message: 'Enter valid billing address if it is different from shipping address',
			path: ['defaultBillingAddress'],
		},
	);

export type AccountFormValues = Customer & {
	password?: string | null;
	billingIsSameAsShipping: boolean;
	addressValidationExecuted: boolean;
};