import React, {PropsWithChildren, useMemo, useState} from 'react';
import OnboardingContext from './OnboardingContext.ts';
import {OnboardingContextState, OnboardingMode, OnboardingStepStates} from './domain.ts';

const OnboardingProvider = ({children, initialMode}: PropsWithChildren<OnboardingProviderProps>) => {
	const [selectedDesign, setSelectedDesign] = useState<string | undefined>(undefined);
	const [onboardingStep, setOnboardingStep] = useState<OnboardingStepStates>('design');
	const [mode, setMode] = useState(initialMode);

	// TODO: Possibly remove useMemo since these operations do not seem expensive unless it prevents context triggered re-renders
	const value = useMemo<OnboardingContextState>(() => ({
		selectedDesign,
		setSelectedDesign,
		onboardingStep,
		setOnboardingStep,
		mode,
		setMode,
	}), [selectedDesign, setSelectedDesign, onboardingStep, setOnboardingStep, mode, setMode]);

	return <OnboardingContext.Provider value={value}>
		{children}
	</OnboardingContext.Provider>;
};

interface OnboardingProviderProps {
	initialMode: OnboardingMode;
}

export default OnboardingProvider;
