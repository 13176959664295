import React, {useCallback, useMemo} from 'react';
import {AddressBookCollectionModel, AddressModel} from '@mgp-fe/shared/core-api/domain/address.ts';
import {
	AddressBookMutationData,
	AddressBookRequest,
	AddressBookRequestSchema,
	useAddressBookMutation,
} from '@mgp-fe/shared/core-api/mutations/address/address-book.ts';
import {FormProvider, useForm, useFormContext} from 'react-hook-form';
import {TextInput} from '@mgp-fe/shared/ui/form/TextInput.tsx';
import {Button} from '@mgp-fe/shared/ui/button';
import {zodResolver} from '@hookform/resolvers/zod';
import {AxiosResponse} from 'axios';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import {useMutation} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import {humanizeEnumValue} from '@mgp-fe/shared/utils/humanize-string.ts';
import {SelectInput} from '@mgp-fe/shared/ui/form/Select.tsx';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify.ts';
import getRegionsMap from '@mgp-fe/shared/utils/regions-by-country.ts';

export default function AddressForm({
	editedAddress,
	onSuccess,
	setAsDefaultShipping,
	setAsDefaultBilling,
}: AddressFormProps) {
	const {notifyWarning} = useNotify();
	const updateAddressMutation = useAddressBookMutation({
		onSuccess: (data, variables, context) => {
			if (onSuccess) onSuccess(data, variables, context);
		},
	});
	const validationMutation = useMutation({
		mutationKey: keysResolver(endpoints.address.validate, 'post'),
		mutationFn: (address: AddressModel) => coreApiClient.post<AddressValidationResponse>(endpoints.address.validate, address),
	});
	const form = useForm<AddressBookRequest>({
		resolver: zodResolver(AddressBookRequestSchema),
		defaultValues: {
			...(editedAddress ? {address: editedAddress.address} : {}),
			setAsDefaultShipping,
			setAsDefaultBilling,
		},
	});

	const submitHandler = useCallback(async (data: AddressBookRequest) => {
		if (!data.validationExecuted && data.address) {
			form.setValue('validationExecuted', true);
			try {
				const validationResponse = await validationMutation.mutateAsync(data.address);
				if ((validationResponse.data.result.address.missingComponentTypes || []).length > 0 || (validationResponse.data.result.address.unconfirmedComponentTypes || []).length > 0) {
					return;
				}
			} catch (e) {
				notifyWarning('Address validation was not successful.');
			}
		}

		updateAddressMutation.mutate({
			data: data,
			update: editedAddress,
		});
	}, [editedAddress, updateAddressMutation]);

	const isAddressValid = useMemo(
		() => (validationMutation.data?.data?.result.address.missingComponentTypes || []).length === 0 && (validationMutation.data?.data?.result.address.unconfirmedComponentTypes || []).length === 0,
		[validationMutation.data],
	);

	return <FormProvider {...form}>
		<form onSubmit={form.handleSubmit(d => submitHandler(d))} className='flex flex-col horizontal-labels w-full'>
			<AddressFormFields/>

			<div className='grid grid-cols-2 gap-mini mt-small'>
				<p className='col-span-2 text-secondary font-medium text-lg'>Make as default:</p>
				<label className='!flex-row !items-center !gap-small !mt-0'>
					<input type='checkbox' {...form.register('setAsDefaultShipping')}/>
					<span>shipping</span>
				</label>

				<label className='!flex-row !items-center !gap-small !mt-0 '>
					<input type='checkbox' {...form.register('setAsDefaultBilling')}/>
					<span>billing</span>
				</label>
			</div>

			<input type='hidden' {...form.register('validationExecuted', {value: false})}/>

			{isAddressValid
				? <Button
					state={updateAddressMutation.status === 'loading' || validationMutation.status === 'loading' ? 'loading' : 'idle'}
					type='submit'
					size='md'
					className='mt-small'>
					Save
				</Button>
				: <Alert variant='warning' className='mb-0'>
					<AlertTitle>Please confirm your address</AlertTitle>
					<AlertDescription className='flex flex-col gap-small'>
						<span><strong>{[
							...(validationMutation.data?.data.result.address.missingComponentTypes || []),
							...(validationMutation.data?.data.result.address.unconfirmedComponentTypes || []),
						].map(v => humanizeEnumValue(v)).join(', ')}</strong> could be incorrect. Please check it.
						</span>
						<Button
							state={updateAddressMutation.status}
							size='md'
							variant='outline'>
							Confirm & Save
						</Button>
					</AlertDescription>
				</Alert>}
		</form>
	</FormProvider>;
}

export function AddressFormFields({addressProperty = 'address'}: { addressProperty?: string }) {
	const form = useFormContext();
	const countryCode = form.watch(`${addressProperty}.countryCode`);

	return <>
		<TextInput name={`${addressProperty}.lineOne`} label='Address line 1'/>
		<TextInput name={`${addressProperty}.lineTwo`} label='Address line 2'/>

		<div className='grid grid-cols-3 gap-mini'>
			<TextInput name={`${addressProperty}.city`} label='City' labelClassName='col-span-2'/>
			<TextInput name={`${addressProperty}.postCode`} label='Post code'/>
		</div>
		<div className='grid grid-cols-2 gap-mini'>
			<div>
				<SelectInput
					name={`${addressProperty}.region`}
					label='State / Region'
					disabled={!countryCode}
					options={countryCode ? getRegionsMap(countryCode) : {}}
					emptyOption={'- select - '}
				/>
			</div>
			<div>
				<SelectInput
					name={`${addressProperty}.countryCode`}
					label='Country'
					options={{
						'US': 'United States',
						'CA': 'Canada',
						'AU': 'Australia',
					}}
					emptyOption='- select -'/>
			</div>
		</div>
	</>;
}

interface AddressFormProps {
	editedAddress?: AddressBookCollectionModel;
	setAsDefaultShipping?: boolean;
	setAsDefaultBilling?: boolean;
	onSuccess?: (data: AxiosResponse<AddressBookCollectionModel>, variables: AddressBookMutationData, context: unknown) => void;
}

export interface AddressValidationResponse {
	responseId: string; //UUID from Google
	result: {
		address: {
			addressComponents: {
				componentName: string;
				componentType: string;
				confirmationLevel: 'CONFIRMED' | 'UNCONFIRMED_BUT_PLAUSIBLE' | 'UNCONFIRMED_AND_SUSPICIOUS';
			}[];
			missingComponentTypes?: string[];
			unconfirmedComponentTypes?: string[];
		};
		verdict: {
			hasUnconfirmedComponents: boolean;
		};
	};
}