'use client';

import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {TextInput} from '@mgp-fe/shared/ui/form/TextInput.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import {AlertCircle} from 'lucide-react';
import useChangePasswordMutation, {
	ChangePasswordMutationData,
	ChangePasswordMutationDataSchema,
} from '@mgp-fe/shared/core-api/mutations/user/change-password.ts';

export default function ChangePasswordForm({onSuccess}: ChangePasswordFormProps) {
	const form = useForm<ChangePasswordMutationData>({
		resolver: zodResolver(ChangePasswordMutationDataSchema),
	});
	const changePassword = useChangePasswordMutation({
		onSuccess: onSuccess,
	});

	return <FormProvider {...form}>
		<form
			onSubmit={form.handleSubmit(d => changePassword.mutate(d))}
			className='flex flex-col horizontal-labels w-full'>

			<TextInput label='Current Password' type='password' name='currentPassword' />
			<TextInput label='New Password' type='password' name='newPassword' />

			<Button
				className='mt-6'
				size='lg'
				type='submit'
				state={changePassword.status}>
				Update password
			</Button>

			{changePassword.isError ? <Alert variant='destructive' className='mb-0'>
				<AlertTitle className='flex gap-2 items-center justify-start'>
					<AlertCircle className='h-4 w-4'/>
					<span>{changePassword.error.response?.data['hydra:title'] || 'Error'}</span>
				</AlertTitle>
				<AlertDescription>
					{changePassword.error.response?.data['hydra:description'] || 'Unknown error'}
				</AlertDescription>
			</Alert> : ''}
		</form>
	</FormProvider>;
}

interface ChangePasswordFormProps {
	onSuccess: () => void;
}