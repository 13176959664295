import React, {useEffect, useState} from 'react';
import {ProductCollectionModel} from '@mgp-fe/shared/core-api/domain/product.ts';
import {Card} from '@mgp-fe/shared/ui/card.tsx';
import ProductCartControls from '@mgp-fe/shared/modules/shop/components/ProductCartControls.tsx';
import MoneyFormatted from '@mgp-fe/shared/modules/formatters/MoneyFormatted.tsx';
import ArrowRight from '@mgp-fe/shared/assets/icons/arrow-right.svg';
import {resolveEnvVar} from '@mgp-fe/shared/utils';

export default function ProductPackageCard(props: ProductPackageCardProps) {
	const [isWebEnv, setIsWebEnv] = useState(false);

	useEffect(() => {
		const webAppUrl = resolveEnvVar('webAppUrl');
		const currentUrl = window.location.href;
		setIsWebEnv(currentUrl.includes(webAppUrl));
	}, []);

	const detailsUrl = isWebEnv ? `pricing/${props.product.id}` : `product-details/${props.product.id}`;

	return <Card
		className='relative overflow-hidden flex flex-col md:flex-row items-stretch min-h-[30rem] md:justify-evenly justify-between mb-8'>
		{props.product.extraLabel ? <div
			className='py-3 md:z-[50] md:w-[500px] md:pr-36 md:pl-12 bg-secondary-900 md:absolute md:-rotate-[30deg] text-muted text-center md:-left-16 md:top-8'>{props.product.extraLabel}</div>
			: ''
		}
		{props.product.titleImage ?
			<div
				className='relative overflow-hidden w-full flex flex-col items-center h-auto md:h-auto '
				style={{
					boxShadow: '-5px -105px 100% rgba(255, 255, 255, 0.02) inset',
				}}>
				<div
					className='z-10 absolute w-full h-4/5 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 aspect-square blur-lg'
					style={{
						background: 'radial-gradient(ellipse farthest-corner at 50%, rgba(163, 222, 0, 0.25), rgba(31, 40, 9, 1), rgba(15, 16, 13, 0.14), black)',
					}}/>
				{props.product.titleImage ? <div className='h-fit w-fit my-auto flex'>
					<img
						className='h-2/5 md:h-fit w-full md:w-4/5 mx-auto z-20 mt-10 md:mt-0'
						src={props.product.titleImage || ''}
						alt={props.product.name}
					/>
				</div> : ''}

				<div className='flex flex-wrap absolute z-50 top-2 right-2 w-fit gap-mini'>
					{props.product.promotionActions.map(pa => <div
						key={pa['@id']}
						className='w-fit bg-secondary text-muted/90 py-mini px-small rounded-full'>
                        -{pa.percentageDiscount}% Off
					</div>)}
				</div>
			</div> : ''
		}

		<article className='flex flex-col w-full md:w-1/2 shrink-0 p-8'>
			<div className='flex flex-col lg:flex-row gap-x-2 justify-between'>
				<h3 className='text-primary font-medium uppercase text-4xl flex leading-tight mx-auto lg:mx-0 items-center'>
					{props.product.name || 'Untitled product'}
				</h3>
				<i className='flex flex-row lg:flex-col gap-x-2 shrink-0 leading-none font-medium text-muted/90 relative items-end justify-end mt-small'>
					<span className='text-lg'>
						{props.product.discountedPrice.amount === props.product.price.amount ? 'only' :
							<span className='line-through text-muted/50 inline-block'><MoneyFormatted
								money={props.product.price}/></span>}
					</span>
					<span className='inline-block text-4xl'>
						<MoneyFormatted money={props.product.discountedPrice}/>
					</span>
				</i>
			</div>

			<h4 className='text-xl font-medium mt-8 text-primary'>Includes</h4>
			<ul className='list-disc'>
				{props.product.packageProducts.map(p => <li key={p.id} className='flex  text-muted/90 gap-mini'><span
					className='flex text-primary  gap-small'><ArrowRight className='fill-primary mt-1.5'/>{p.quantity}x </span>{p.product.name}
				</li>)}
			</ul>
			<div className='w-full flex flex-col-reverse gap-small xl:gap-x-xxlarge xl:flex xl:flex-row xl:justify-between pt-medium mt-auto items-stretch'>
				<ProductCartControls product={props.product}/>
				<div className='flex items-center'>
					<a className='text-secondary' href={detailsUrl}>show details</a>
				</div>
			</div>
		</article>
	</Card>;
}

interface ProductPackageCardProps {
	product: ProductCollectionModel;
}