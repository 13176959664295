import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {TextInput} from '@mgp-fe/shared/ui/form/TextInput.tsx';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import useRedeemCouponMutation, {
	RedeemCouponMutationRequest,
} from '@mgp-fe/shared/core-api/mutations/cart/apply-coupon.ts';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify.ts';
import {useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';

export default function RedeemCouponForm(props: RedeemCouponFormProps) {
	const form = useForm<RedeemCouponMutationRequest>();
	const queryClient = useQueryClient();

	const {notifySuccess} = useNotify();

	const redeemCouponMutation = useRedeemCouponMutation({
		onSuccess: async () => {
			await queryClient.invalidateQueries(keysResolver(endpoints.cart.my.item));
			notifySuccess({message: 'Coupon redeemed'});
			props.onSuccess && props.onSuccess();
			form.reset();
		},
	});

	return <FormProvider {...form}>
		<form
			onSubmit={form.handleSubmit(d => redeemCouponMutation.mutate({code: d.code.replaceAll(' ', '')}))}
			className='flex flex-col gap-small horizontal-labels'>
			<p>
				Please enter your promocode.
			</p>

			<TextInput name='code' label='Promo Code' required />
			<Button state={redeemCouponMutation.status}>
				Redeem & use
			</Button>

			{redeemCouponMutation.isError ? <Alert variant='destructive'>
				<AlertTitle>Promocode not redeemed</AlertTitle>
				<AlertDescription>
					{redeemCouponMutation.error?.response?.data['hydra:description'] || 'Unknown error'}
				</AlertDescription>
			</Alert> : null}
		</form>
	</FormProvider>;
}

interface RedeemCouponFormProps {
	onSuccess?: () => void;
}