'use client';

import React, {useEffect, useMemo} from 'react';
import AuthContext from '@mgp-fe/shared/modules/auth/state/AuthContext.ts';
import {AuthContextValue} from '@mgp-fe/shared/modules/auth/domain.ts';
import useLoginMutation from '@mgp-fe/shared/core-api/mutations/auth/login';
import {AUTH_IMPERSONATION_STORAGE_KEY, AUTH_LOCAL_STORAGE_KEY} from '@mgp-fe/shared/core-api/domain/base.ts';
import useMeQuery from '@mgp-fe/shared/core-api/queries/user/me.ts';
import {User} from '@mgp-fe/shared/core-api/domain/user.ts';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '@mgp-fe/shared/ui/tabs.tsx';
import LoginForm from '@mgp-fe/shared/modules/auth/components/LoginForm.tsx';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import {useQueryClient} from '@tanstack/react-query';
import {useGetRecaptchaToken} from '@mgp-fe/shared/hooks/useGetRecaptchaToken.ts';
import RegisterProxyContent from '@mgp-fe/shared/modules/auth/components/RegisterProxyContent.tsx';
import useLocalStorage from '@mgp-fe/shared/hooks/useLocalStorage.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import getClientEnv from '@mgp-fe/shared/utils/env-var-resolver.ts';

export default function AuthContextProvider<T extends User>({
	children,
	loginModalMode = 'with_register_tab',
}: AuthContextProviderProps) {
	const recaptchaToken = useGetRecaptchaToken('login');
	const queryClient = useQueryClient();
	const [, setToken] = useLocalStorage<string | null>(AUTH_LOCAL_STORAGE_KEY, null);
	const [impersonation, setImpersonation] = useLocalStorage<boolean>(AUTH_IMPERSONATION_STORAGE_KEY, false);
	const login = useLoginMutation();
	const userQuery = useMeQuery<T>();
	const displayLoginModal = useToggle({initialState: false});
	const appName = getClientEnv('appName');

	const value = useMemo<AuthContextValue<T>>(() => ({
		login: async ({username, password, recaptchaToken: sentToken, userType}) => {
			return await login.mutateAsync({
				username,
				password,
				userType: userType ?? appName,
				recaptchaToken: sentToken || await recaptchaToken(),
			});
		},
		logout: async () => {
			setImpersonation(false);
			localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
			window.location.href = '/';
		},
		user: userQuery.data?.data || null,
		impersonation: impersonation,
		isUserLoading: userQuery.isFetching,
		isUserLoaded: userQuery.isFetched,
		displayLoginModal: displayLoginModal,
	}), [displayLoginModal, login, queryClient, userQuery.data?.data, userQuery.isFetching, userQuery.isFetched]);

	useEffect(() => {
		userQuery.refetch();
	}, []);

	useEffect(() => {
		if (!window) return;
		const searchParams = new URLSearchParams(window.location.search);
		if (!searchParams.get('impersonateToken')) return;
		value.logout().then(() => {
			setToken(searchParams.get('impersonateToken') || '');
			setImpersonation(true);
		});
	}, []);

	const userType = () => {
		if (appName === 'web') {
			if (window.location.href.includes('partners.')) return 'partner';
			else return 'customer';
		}

		return appName;
	}

	return <AuthContext.Provider value={value}>
		{impersonation ? <div
			className='w-fit mx-auto bg-secondary-950 text-muted/80 py-mini px-medium text-center z-50 fixed left-0 right-0 bottom-0'>
			You are impersonating {value.user?.name} ({value.user?.email || 'Loading...'}).
			<Button size='sm' variant='link' onClick={value.logout}>
				<FontAwesomeIcon icon='close' className='mr-2'/>
				End session
			</Button>
		</div> : ''}

		{children}

		<Modal isOpen={displayLoginModal.state} onClose={displayLoginModal.off} title='Login'>
			{{
				'with_register_tab': <Tabs defaultValue='login'>
					<TabsList>
						<TabsTrigger value='login'>Login</TabsTrigger>
						<TabsTrigger value='register'>I don&#39;t have an account</TabsTrigger>
					</TabsList>
					<TabsContent value='login'>
						<p className='mt-2'>Please enter your username and password to login.</p>
						<LoginForm onSuccess={displayLoginModal.off} appName={userType()}/>
					</TabsContent>
					<TabsContent value='register' className='pt-small'>
						<RegisterProxyContent/>
					</TabsContent>
				</Tabs>,
				'login_only': <>
					<p className='mt-2'>Please enter your username and password to login.</p>
					<LoginForm onSuccess={displayLoginModal.off} appName={userType()}/>
				</>,
			}[loginModalMode]}
		</Modal>
	</AuthContext.Provider>;
}

export interface AuthContextProviderProps {
	children: React.ReactNode;
	loginModalMode?: 'with_register_tab' | 'login_only';
}